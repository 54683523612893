import { useCallback, useEffect, useState } from "react";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useInterval } from "usehooks-ts";
import { TailSpin } from "react-loader-spinner";
import { firebaseConfig } from "./config";
import { SentimentChart } from "./components/charts/SentimentChart";
import { fetchSentiment } from "./services/sentiment";

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

function App() {
  const [data, setSentimentData] = useState({ bengals: [], rams: [] });
  logEvent(analytics, "page_view");

  const retreiveSentimentData = useCallback(() => {
    (async () => await fetchSentiment(setSentimentData))();
  }, [setSentimentData]);

  useEffect(retreiveSentimentData, [retreiveSentimentData, setSentimentData]);
  useInterval(retreiveSentimentData, 300000);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex" }}>
        <h1>
          Superbowl 2022 Sentiment Analysis by{" "}
          <a
            target="_blank"
            href="https://spark64.com"
            style={{ color: "#3D88FA" }}
            rel="noreferrer"
          >
            Spark 64
          </a>
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "90%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {data.bengals.length === 0 && data.rams.length === 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TailSpin height={80} width={80} color="#F47B3F" />
            <p>Loading sentiment...</p>
          </div>
        ) : (
          <SentimentChart
            data={[
              {
                id: "Cincinnati Bengals",
                data: data.bengals,
              },
              {
                id: "Los Angeles Rams",
                data: data.rams,
              },
            ]}
            yLabel="Sentiment"
            xLabel="Time"
          />
        )}
      </div>
    </div>
  );
}

export default App;
