import { parseDateStringToTime } from "../util";
import * as R from "ramda";
import { downloadBlob } from "./firestore";

interface SentimentData {
  x: Date;
  y: Number;
}

const bengalsTouchdowns = ["2022-02-14 00:32:00+00:00", "2022-02-14 01:33:00+00:00"];
const ramsTouchdowns = ["2022-02-13 23:50:00+00:00", "2022-02-14 00:20:00+00:00", "2022-02-14 02:52:00+00:00"];

export const fetchSentiment = async (setSentimentData: Function) => {
  try {
    const bengals = await downloadBlob("superbowl-sentiment-bengals.csv");
    const rams = await downloadBlob("superbowl-sentiment-rams.csv");

    const _bengals = R.pipe(transformSentimentData, addTouchdowns(bengalsTouchdowns))(bengals);
    const _rams = R.pipe(transformSentimentData, addTouchdowns(ramsTouchdowns))(rams);
    setSentimentData(rejectUniqSentimentData(_rams, _bengals));
  } catch (e) {}
};

const addTouchdowns = (touchdowns: string[]) => (data: SentimentData[]) => {
  const _touchdowns = R.map(parseDateStringToTime, touchdowns);
  return R.map((point) => R.includes(point.x, _touchdowns) ? { ...point, touchdown: true } : point ,data)
}

const transformSentimentData = R.pipe(
  R.trim,
  R.split("\n"),
  R.map(R.split(",")),
  R.map((v: string[]) => ({ x: v[0], y: v[1] })),
  R.map(R.evolve({ x: parseDateStringToTime, y: Number }))
);

const rejectUniqSentimentData = (rams: SentimentData[], bengals: SentimentData[]) => {
  const diff = R.symmetricDifferenceWith<SentimentData>(R.eqBy<SentimentData, any>(R.prop("x")), rams, bengals);
  return { rams: R.reject(R.includes(R.__, diff), rams), bengals: R.reject(R.includes(R.__, diff), bengals)}
}
