import React from "react";

import { SliceTooltipProps } from "@nivo/line";
import { dateToTimeWithShortOffset } from "../../util";

export const SentimentChartSliceTooltip = ({ slice }: SliceTooltipProps) => (
  <div
    style={{
      background: "white",
      padding: "9px 12px",
      border: "1px solid #ccc",
      minWidth: 250,
    }}
  >
    <div style={{ display: "flex", justifyContent: "center" }}>
      <span>
        <strong>
          {dateToTimeWithShortOffset(slice.points[0].data.x as Date)}
        </strong>
      </span>
    </div>
    {slice.points.map((props) => {
      const { id, serieId, serieColor, data } = props;
      const { y } = data;

      return (
        <div
          key={id}
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: serieColor,
            padding: "3px 0",
          }}
        >
          <span>{serieId}</span>
          <span style={{ color: y > 0 ? "green" : "red" }}>
            <strong>{y > 0 ? "Positive" : "Negative"}</strong>
          </span>
        </div>
      );
    })}
  </div>
);
