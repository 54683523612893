import { PointSymbolProps, ResponsiveLine, Serie } from "@nivo/line";
import { Football } from "../icons/football";
import { SentimentChartSliceTooltip } from "../tooltips/SentimentChartSliceTooltip";

const dateFormat = "%H:%M"

interface SentimentChartProps {
  data: Serie[];
  xLabel: string;
  yLabel: string;
  fontSize?: number;
}

const TouchdownSymbol = (props: PointSymbolProps) => {
  const { datum } = props;
  const { touchdown } = datum;

  if (!touchdown) {
    return null;
  }

  return <Football height={20} width={20} x={-10} y={-10} />
}

export const SentimentChart = (props: SentimentChartProps) => {
  const { data, xLabel, yLabel, fontSize = 16 } = props;

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 200, bottom: 80, left: 100 }}
      xScale={{ type: 'time', format: dateFormat, precision: 'minute' }}
      yScale={{
        type: "linear" as any,
        min: "auto",
        max: "auto",
      }}
      colors={["#FB4F14", "#003594"]}
      yFormat=">-.2f"
      curve="monotoneX"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: xLabel,
        legendOffset: 50,
        legendPosition: "middle",
        format: dateFormat,
        tickValues: 10,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yLabel,
        legendOffset: -70,
        legendPosition: "middle",
      }}
      theme={{
        fontSize,
        axis: {
          legend: {
            text: {
              fontSize,
            },
          },
        },
        legends: {
          text: {
            fontSize,
          },
        },
      }}
      enablePoints={true}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointSymbol={TouchdownSymbol}
      pointBorderColor={{ from: "serieColor", modifiers: [] }}
      pointLabelYOffset={-12}
      useMesh={true}
      enableSlices="x"
      sliceTooltip={SentimentChartSliceTooltip}
      legends={[
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 170,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 150,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};
