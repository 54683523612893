import { getStorage, ref, getDownloadURL } from "firebase/storage";
import axios from "axios";
import { app } from "../App";

export const downloadBlob = async (fileName: string): Promise<any> => {
  const storage = getStorage(app);
  const url = await getDownloadURL(ref(storage, fileName));
  const response = await axios.get(url, { responseType: "blob" });
  return await response.data.text();
}
